.container {
  width: 50%;
  padding-right: 15px;
  padding-left: 15px;
  /* margin-right: auto; */
  margin-left: 400px !important;
  /* background-color: #ad789fef; */
}
.main-container-for-Addaccount {
  background-color: #ad789fef;
}

@media (max-width: 768px) {
  .main-container-for-Addaccount {
    /* width: 107%;
    padding-right: 8px; */
    padding-left: 41px;
    /* margin-right: auto !important;
    margin-left: auto !important; */
    /* margin-top: 1em; */
    background-color: #ad789fef;
  }
  .row.mb-2 {
    flex-direction: column;
  }
}

.form-control {
  border-radius: 15px;
  height: 5.8vh;
}

.form-label {
  font-weight: 500;
}

.manager-btn {
  padding: 0.6em 1.8em;
  border: 2px solid #17c3b2;
  position: relative;
  overflow: hidden;
  background-color: #0d6efd;
  text-align: center;
  /* text-transform: uppercase; */
  font-size: 16px;
  transition: 0.7s;
  z-index: 1;
  font-family: inherit;
  color:white;
  border-radius: 30px;
}

.manager-btn::before {
  content: "";
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #17c3b2;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

.manager-btn:hover::before {
  width: 105%;
}

.manager-btn:hover {
  color: #111;
  font-weight: bold;
}
